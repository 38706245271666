import React from "react";

function HomeAboutFeatures() {
  return (
    <div id="features" className="wrapper spacer p-top-xl">
      <div className="row gutter-width-md with-pb-lg">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div className="feature-item">
            <h2>Na čem si zakládáme</h2>
          </div>
        </div>

        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div className="feature-item">
            <h4>Kvalita</h4>
            <p>
              V naší ordinaci se setkáte s nejmodernějšími přístroji, jako je
              CBCT (3D snímek), terapeutický laser, chirurgický ultrazvuk,
              AirFlow, vysokofrekvenční kauter, intraorální skener, špičkový
              apexlokátor a endomotor. Díky tomuto vybavení se snažíme maximálně
              zdokonalit naši péči a minimalizovat riziko neúspěchu.
            </p>
          </div>
        </div>

        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div className="feature-item">
            <h4>Pečlivost</h4>
            <p>
              Pečlivost - Pečlivým dodržováním technologickým procesů
              prodlužujeme životnost našich výkonů.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAboutFeatures;
