import React from "react";
import { Link } from "react-scroll";

const HomeAboutText = () => {
  return (
    <div id="about-text" className="wrapper">
      <div className="row gutter-width-md with-pb-md">
        <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <div className="description-2">
            <h2>Jsme rodinná zubní ordinace sídlící v Chrudimi</h2>
          </div>
        </div>

        <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <div className="description-2">
            <p>
              Náš dvougenerační tým kombinuje dlouholeté zkušenosti s
              inovativními nápady mládí. Každá generace do oboru něčím přispívá,
              a díky tomu tvoříme skvělý tým.
            </p>
          </div>

          <div className="description-2 spacer p-top-lg">
            <p>
              Klademe důraz na prevenci a hygienu, a tím se snažíme předcházet
              závažným problémům našich pacientů. Pokud se problém vyskytne,
              řešíme ho pomocí nejmodernějších technik a přístrojů. Našim cílem
              jsou spokojení pacienti, kteří si udrží zdravé zuby po celý život.
            </p>
          </div>

          <div className="button spacer p-top-lg">
            <Link
              to="services"
              href="#services"
              className="btn btn-primary scroll-to-id"
            >
              Jaké služby nabízíme
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAboutText;
