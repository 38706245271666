import React from "react";

const Maps = () => {
  return (
    <div style={{ width: "100%", height: "450px" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2567.2085896278463!2d15.796544112126545!3d49.951189523066994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470dca22134fe48f%3A0xbd68849293f19cb0!2sOpletalova%2015%2C%20537%2001%20Chrudim%20II!5e0!3m2!1scs!2scz!4v1727265210916!5m2!1scs!2scz"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Maps"
      ></iframe>
    </div>
  );
};

export default Maps;
