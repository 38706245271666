import React, { useState } from "react";

const teamData = [
  {
    id: 1,
    name: "Pavel Novák",
    position: "MUDr.",
    description:
      "MUDr. Pavel Novák je praktický zubní lékař, který založil soukromou praxi v r. 1992. Od té doby se stal specialistou v oborech parodontologie, implantologie, endodoncie a estetické stomatologie. Ve své zubní praxi využívá nejmodernější postupy a materiály.",
    imgSrc: "assets/img/team/pavelnovak.jpg",
    imgAlt: "Pavel Novák",
  },
  {
    id: 2,
    name: "Alžběta Rabasová",
    position: "MDDr.",
    description:
      "MDDr. Alžběta Rabasová je absolventka oboru zubního lékařství na Lékařské fakultě Univerzity Palackého. Do společné praxe by chtěla vnést nejnovější poznatky ze svého studia, píli a dobrou náladu.",
    imgSrc: "assets/img/team/alzbetarabasova.jpg",
    imgAlt: "Alžběta Rabasová",
  },
];

const TeamMember = ({ member, isOpen, handleClick }) => (
  <div className="col-sm-12 col-md-6">
    <div className="team-member">
      <div className="team-member-top" onClick={() => handleClick(member.id)}>
        <div className="img object-fit">
          <div className="object-fit-cover">
            <img src={member.imgSrc} alt={member.imgAlt} />
          </div>
        </div>
      </div>

      <div className="team-member-content">
        <div className="team-member-position">
          <p>{member.position}</p>
        </div>

        <h4 className="team-member-t-head">{member.name}</h4>

        <div className="team-member-description">
          <p>{member.description}</p>
        </div>
      </div>
    </div>
  </div>
);

const OurTeam = () => {
  const [openStates, setOpenStates] = useState({});

  const handleClick = (id) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <section id="our-team" className="block spacer p-top-xl">
      <div className="wrapper">
        <div className="title text-max-width">
          <h2>Kdo bude pečovat o Váš úsměv?</h2>
        </div>

        <div className="row gutter-width-md with-pb-md">
          {teamData.map((member) => (
            <TeamMember
              key={member.id}
              member={member}
              isOpen={openStates[member.id] || false}
              handleClick={handleClick}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
