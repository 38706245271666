import React from "react";
import { Link } from "react-scroll";

const HomeInfo = () => {
  return (
    <section id="information" className="block">
      <div className="wrapper">
        <div className="row no-gutters information-items">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="information information-color-1">
              <div className="information-icon">
                <i className="hosco-icon-call-2"></i>
              </div>

              <h4 className="information-t-head">
                V případě akutního problému
              </h4>

              <div className="information-description">
                <p>
                  Pokud u Vás vznikne akutní problém, kontaktujte nás v
                  ordinační době
                  <div className="information-btn">
                    <a
                      title="Volejte"
                      className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase"
                      href="tel:+420725745024"
                    >
                      Volejte zde
                    </a>
                  </div>
                </p>
                <p>V průběhu dovolené se obraťte na zubní pohotovost.</p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="information information-color-2 information-icon-margin">
              <div className="information-icon">
                <i className="hosco-icon-dental-care"></i>
              </div>

              <h4 className="information-t-head">Kde nás najdete?</h4>

              <div className="information-description">
                <p>
                  Naši ordinaci najdete na adrese:
                  <br /> Opletalova 15, Chrudim II
                </p>
              </div>

              <div className="information-btn">
                <a
                  to="google-maps"
                  title="See on map"
                  className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase scroll-to-id"
                  href="https://maps.app.goo.gl/HoUD6x1RuFCGrPdUA"
                  target="_blank"
                >
                  Zobrazit na Google Maps
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="information information-color-3">
              <div className="information-icon">
                <i className="hosco-icon-calendar"></i>
              </div>

              <h4 className="information-t-head">Otevírací doba</h4>

              <div className="information-description">
                <p>
                  <span className="d-flex justify-content-between flex-wrap">
                    <span>Po:</span>
                    <span className="text-right">08:00 - 14:00</span>
                  </span>
                </p>
                <p>
                  <span className="d-flex justify-content-between flex-wrap">
                    <span>Út:</span>
                    <span className="text-right">10:00 - 15:00</span>
                  </span>
                </p>
                <p>
                  <span className="d-flex justify-content-between flex-wrap">
                    <span>St:</span>
                    <span className="text-right">08:00 - 13:00</span>
                  </span>
                </p>
                <p>
                  <span className="d-flex justify-content-between flex-wrap">
                    <span>Čt:</span>
                    <span className="text-right">08:00 - 14:00</span>
                  </span>
                </p>
                <p>
                  <span className="d-flex justify-content-between flex-wrap">
                    <span>Pá:</span>
                    <span className="text-right">08:00 - 13:00</span>
                  </span>
                </p>
                <p>
                  <span className="d-flex justify-content-between flex-wrap">
                    <span>So - Ne:</span>
                    <b>
                      <span className="text-right">Zavřeno</span>
                    </b>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeInfo;
