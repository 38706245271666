import React, { useState } from "react";
import { Collapse, Modal, Backdrop, Fade } from "@material-ui/core";
import AccordionItems from "../../data/services/accordionOneItems.json";

const Accordion1 = () => {
  const [open, setOpen] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (e) => {
    let target = e.target.getAttribute("data-target");

    if (open === target) {
      setOpen("#collapse-0");
    } else {
      setOpen(target);
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // Structured table data with headers and sections as per the provided image
  const tableData = [
    { header: "Přímo hrazené stomatologické výkony 2024", type: "header" },
    ["Fotokompozitní výplně", ""],
    ["Jednoplošková výplň", "1,500 CZK"],
    ["Dvouplošková výplň", "2,000 CZK"],
    ["Tříplošková výplň", "2,400 CZK"],
    ["Přímá estetická faseta přes hranu incize", "3,000 CZK"],
    { header: "Ošetření kořenových kanálků", type: "header" },
    ["1 kořenový kanálek", "2,500 CZK"],
    ["2 a více kořenových kanálků", "1,800 CZK / 1 kanálek"],
    [
      "Přeošetření nevyhovujícího ošetření kořenových kanálků",
      "2,500 CZK / 1 kanálek",
    ],
    { header: "Postendodontické ošetření", type: "header" },
    [
      "Adhezivní kompozitní dostavba korunkové části (1 titanový čep)",
      "2,000 CZK",
    ],
    [
      "Adhezivní kompozitní dostavba korunkové části (2 titanové čepy)",
      "2,500 CZK",
    ],
    ["Adhezivní kompozitní dostavba korunkové části (bez čepu)", "2,000 CZK"],
    { header: "Očištění zubů pomocí Air-Flow metody", type: "header" },
    ["Očištění zubů", "1,400 CZK"],
    { header: "Bělení zubů", type: "header" },
    ["Příprava nosiče pro domácí bělení (1 čelist)", "2,250 CZK"],
    ["Ordinační bělení (1 hodina)", "2,000 CZK"],
    { header: "Další výkony", type: "header" },
    ["Separace korunky", "500 CZK"],
    ["Kauterizace", "500 CZK"],
    ["Koferdam", "500 CZK"],
    ["Chirurgický ultrazvuk", "1,200 CZK"],
    [
      "Úprava vlastního zubu pro augmentaci kosti + uskladnění (Tooth fairy)",
      "1,500 CZK",
    ],
    ["Augmentační materiál (Tooth transformer)", "3,000 CZK"],
    ["Laserová terapie", "1,500 CZK"],
    ["CT snímek", "1,500 CZK"],
    ["OPG", "300 CZK"],
    { header: "Dentální implantáty", type: "header" },
    ["Zavedení implantátu systému Meisinger", "17,000 CZK"],
    ["Zavedení implantátu systému Astra", "18,000 CZK"],
    { header: "Stomatologické výrobky 2024 - fixní protetika", type: "header" },
    { header: "Samostatné korunky", type: "subheader" },
    ["Korunka celokeramická", "7,300 CZK"],
    { header: "Fixní můstky", type: "subheader" },
    ["Pillířová korunka můstku celokeramická", "7,300 CZK"],
    ["Mezičlen můstku celokeramický", "7,300 CZK"],
    { header: "Protetika nesená implantáty", type: "subheader" },
    ["Samostatná korunka nesená implantátem", "13,000 CZK"],
    { header: "Provizorní konstrukce", type: "subheader" },
    ["Ochranná korunka", "1,300 CZK"],
    ["Ochranná korunka frézovaná", "1,500 CZK"],
    ["Provizorní náhrada foliovou technikou", "3,000 CZK"],
    ["Ochranný můstek do 6 zubů", "2,500 CZK"],
    ["Ochranný můstek nad 6 zubů", "3,000 CZK"],
    {
      header: "Stomatologické výrobky 2024 - snímatelná protetika",
      type: "header",
    },
    ["Oprava retenčních prvků snímatelné náhrady", "2,000 CZK"],
    ["Oprava prasklé snímatelné náhrady", "2,000 CZK"],
    ["Rozšíření bází snímatelné náhrady", "2,000 CZK"],
    ["Imediátní celková náhrada", "8,000 CZK"],
    ["Snímatelná náhrada v provedení Deflex", "10,000 CZK"],
  ];

  return (
    <div>
      <div id="accordion-services-1" className="accordion accordion-services">
        {AccordionItems &&
          AccordionItems.map((item, key) => {
            return (
              <div
                key={key}
                className={
                  "card " + (open === "#collapse-" + item.id ? "active" : "")
                }
              >
                <div className="card-header" id={"heading-" + item.id}>
                  <button
                    onClick={(e) => handleClick(e)}
                    className={
                      "btn btn-lg btn-link stretched-link " +
                      (open === "#collapse-" + item.id ? "" : "collapsed")
                    }
                    type="button"
                    data-toggle="collapse"
                    data-target={"#collapse-" + item.id}
                    aria-expanded="false"
                    aria-controls={"collapse-" + item.id}
                  >
                    <span className="h4 after">{item.title}</span>
                    <i className="fas fa-angle-right i-large"></i>
                  </button>
                </div>

                <Collapse in={open === "#collapse-" + item.id}>
                  <div
                    id={"collapse-" + item.id}
                    className="collapse show"
                    aria-labelledby={"heading-" + item.id}
                    data-parent={"#accordion-services-" + item.id}
                  >
                    <div className="card-body">
                      <p>{item.description}</p>

                      {/* Clickable text to open modal */}
                      <p className="card-price" onClick={handleModalOpen}>
                        {item.price}
                      </p>
                    </div>
                  </div>
                </Collapse>
              </div>
            );
          })}
      </div>

      {/* Modal for displaying the table */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div
            style={{
              padding: "20px",
              backgroundColor: "white",
              margin: "100px auto",
              width: "90%",
              maxHeight: "85vh",
              overflowY: "scroll",
              color: "black",
            }}
          >
            <h3>Přímo hrazené stomatologické výkony 2024</h3>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <tbody>
                {tableData.map((row, index) => {
                  if (row.type === "header") {
                    return (
                      <tr key={index}>
                        <td
                          colSpan="2"
                          style={{
                            backgroundColor: "#dca47c",
                            textAlign: "left",
                            fontWeight: "bold",
                            padding: "8px",
                            color: "white",
                            border: "1px solid black",
                          }}
                        >
                          {row.header}
                        </td>
                      </tr>
                    );
                  }
                  if (row.type === "subheader") {
                    return (
                      <tr key={index}>
                        <td
                          colSpan="2"
                          style={{
                            backgroundColor: "#e0e0e0",
                            textAlign: "left",
                            fontStyle: "italic",
                            padding: "8px",
                            border: "1px solid black",
                          }}
                        >
                          {row.header}
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <tr key={index}>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        {row[0]}
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        {row[1]}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <button onClick={handleModalClose} className="btn btn-primary mt-5">
              Zavřít
            </button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Accordion1;
